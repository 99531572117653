import React from "react"
import LightboxReact from "lightbox-react"
import "lightbox-react/style.css"
import Img from "gatsby-image"
import { RichText } from 'prismic-reactjs'
import { FaInstagram } from 'react-icons/fa'

const Lightbox = ({
  images,
  selectedImage,
  handleClose,
  handlePrevRequest,
  handleNextRequest,
}) => {
  const array = []

  images.forEach(image =>
    array.push(
      <>
        <Img alt={RichText.asText(image.caption)} fluid={image.imageSharp.childImageSharp.fluid} />
        {image.insta ? 
        <a className="instagram-gallery-link" href={image.insta.url}>
          <FaInstagram /><span>View on Instagram</span>
        </a>
        : 
        <a target="_blank" rel="noreferrer" className="instagram-gallery-link" href="https://www.instagram.com/totaltripscom/">
          <FaInstagram /><span>Instagram</span>
        </a>}
        <div className="lightbox__caption">
          <span>
            {RichText.asText(image.caption)} 
          </span>
        </div>
      </>
    )
  )

  return (
    <LightboxReact
      wrapperClassName="lightbox"
      enableZoom={false}
      clickOutsideToClose={true}
      mainSrc={array[selectedImage]}
      nextSrc={array[(selectedImage + 1) % array.length]}
      prevSrc={array[(selectedImage + array.length - 1) % images.length]}
      onCloseRequest={handleClose}
      onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
      onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
    />
  )
}

export default Lightbox
